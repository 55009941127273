import { Reducer, Store } from 'redux';
import { RouterState } from 'connected-react-router';
import { Saga } from 'redux-saga';
import { SagaInjectionModes } from 'redux-injectors';
// types
import { ILanguageProviderState } from '@/components/LanguageProvider/types';

export interface InjectedStore extends Store {
  injectedReducers: any;
  injectedSagas: any;
  runSaga(saga: Saga<any[]> | undefined, args: any | undefined): any;
}

export interface InjectReducerParams {
  key: keyof ApplicationRootState;
  reducer: Reducer<any, any>;
}

export interface InjectSagaParams {
  key: keyof ApplicationRootState;
  saga: Saga;
  mode?: SagaInjectionModes;
}

// Your root reducer type, which is your redux state types also
export interface ApplicationRootState {
  readonly router: RouterState;
  readonly language: ILanguageProviderState;
  // [INSERT NEW REDUCER KEY ABOVE] < Needed for generating containers seamlessly

  // for testing purposes
  readonly test: any;
}

export enum IUIIcons {
  BACK_NAVIGATION = 'backNavigation',
  BROADCAST_START = 'broadcastStart',
  BROADCAST_STOP = 'broadcastStop',
  CALENDAR = 'calendar',
  CAMERA = 'camera',
  CHAT = 'chat',
  CHECK = 'check',
  COLLAPSE_MENU = 'collapseMenu',
  COLLAPSE_MENU_2 = 'collapseMenu2',
  DELETE = 'delete',
  DOWNLOAD = 'download',
  EMOJI = 'emoji',
  EXPAND_MENU = 'expandMenu',
  EXPAND_MENU_2 = 'expandMenu2',
  EXPO = 'expo',
  FILE_TEXT = 'fileText',
  FILES = 'files',
  FULL_SCREEN_MAX = 'fullScreenMaximize',
  FULL_SCREEN_MIN = 'fullScreenMinimize',
  HAND_LOWER = 'handLower',
  HAND_RAISE = 'handRaise',
  KEYNOTE = 'keynote',
  LOBBY = 'lobby',
  MIC = 'mic',
  MIC_OFF = 'micOff',
  PEOPLE = 'people',
  POLL = 'poll',
  QA = 'qa',
  RAISED_HANDS = 'raisedHands',
  SHARE_SCREEN = 'shareScreen',
  SHARE_SCREEN_OFF = 'shareScreenOff',
  STREAM_JOIN = 'streamJoin',
  STREAM_LEAVE = 'streamLeave',
  TABLE_SWITCH = 'tableSwitch',
  USER = 'user',
  USERS = 'users',
  VIDEO = 'video',
  VIDEO_OFF = 'videoOff',
  VOLUME_OFF = 'volumeOff',
  VOLUME_ON = 'volumeOn',
  RIGHT_ARROW = 'rightArrow',
  LOCK = 'lock',
  CHEVRON_LEFT = 'chevronLeft',
  FILLED_STAR = 'filledStar',
  USER_PLUS = 'userPlus',
  NEW_VIDEO_ON = 'newVideoOn',
  NEW_VIDEO_OFF = 'newVideoOff',
  NEW_MIC_ON = 'newMicOn',
  NEW_MIC_OFF = 'newMicOff',
  CROSS = 'cross',
  ARROW_RIGHT = 'arrowRight',
  CLOSE = 'close',
  LOADER = 'loader',
  SEARCH = 'search',
  FORWARD_NAVIGATION = 'forwardNavigation',
  UPLOAD = 'upload',
  ARROW_DOWN = 'arrowDown',
  USER_SPEAKING = 'userSpeaking',
  FILE_LIST = 'fileList',
  USER_SPEAKING_OUTLINED = 'userSpeakingOutlined',
  MESSAGE = 'message',
  ASTERISK = 'asterisk',
  SIGNAL_TOWER = 'signalTower',
  BOX_ICON_LIST_UL = 'boxIconListUl',
  TIMER_LINE = 'timerLine',
  STOP = 'stop',
  STARTSTREAM = 'startStream',
  STOPSTREAM = 'stopStream',
  DOUBLE_TICK = 'doubleTick',
  ARROW_UP = 'arrowUp',
  FEED = 'feed',
  NEW_CALENDAR = 'newCalendar',
  FEATHER_CALENDAR = 'featherCalendar',
  FEATHER_MESSAGE_CIRCLE = 'featherMessageCircle',
  FEATHER_FILTER = 'featherFilter',
  FEATHER_ARROW_LEFT = 'featherArrowLeft',
  CHEVRON_RIGHT = 'chevronRight',
  FILTER_BAR = 'filterBar',
  GRID_LAYOUT = 'gridLayout',
  ARROW_LEFT = 'arrowLeft',
  SCREEN_SHARE_ON = 'screenShareOn',
  NEW_SCREEN_SHARE_OFF = 'newScreenShareOff',
  NEW_VOLUME_ON = 'newVolumeOn',
  NEW_VOLUME_OFF = 'newVolumeOff',
  NEW_BROADCAST_START = 'newBroadcastOn',
  NEW_BROADCAST_STOP = 'newBroadcastOff',
  NEW_BROADCAST_PAUSE = 'newBroadcastPause',
  NEW_BROADCAST_RESUME = 'newBroadcastResume',
  NEW_STREAM_LEAVE = 'newStreamLeave',
  LEAVE_EXPO = 'leaveExpo',
  CHROME_BROWSER = 'chromeBrowser',
  EDGE_BROWSER = 'edgeBrowser',
  BROWSER = 'browser',
  WIFI = 'wifi',
  SUCCESS_CIRCLE_FILLED = 'successCircleFilled',
  ALERT_CIRCLE_FILLED = 'alertCircleFilled',
  EDIT_FILLED = 'edit',
  HEART_FILLED = 'heart',
  FILTER_FILLED = 'filter',
  EYE = 'eye',
  CLOSE_CIRCLE_FILLED = 'closeCircleFilled',
  IMAGE = 'image',
  VIDEO_RECORDING = 'videoRecording',
  PRESENTATION = 'presentation',
  UPLOAD_FILLED = 'uploadFilled',
  SEND = 'sendIcon',
  REFRESH = 'refresh',
  BAR_CHART_FILLED = 'barChartFilled',
  BRAIN = 'brain',
  EXTERNAL_LINK_LINE = 'externalLinkLine',
  BOOTH_VOLUME_ON = 'boothVolumeOn',
  BOOTH_VOLUME_OFF = 'boothVolumeOff',
  END_CALL = 'endCall',
  KEBAB_MENU = 'kebabMenu',
  BIN = 'bin',
  COPY = 'copy',
  DUPLICATE = 'duplicate',
  PLUS_CIRCLE_FILLED = 'plusCircleFilled',
  KEY_FILLED = 'keyFilled',
  TICKET = 'ticket',
  ADD = 'add',
  GLOBE = 'globe',
  COMPASS = 'compass',
  EXTENSION = 'extension',
  FEEDV2 = 'feedv2',
  FEEDV3 = 'feedv3',
  GIF = 'GIF',
  SAFARI_BROWSER = 'safariBrowser',
  MORE = 'more',
  BROWSER_SUCCESS = 'browserSuccess',
  BROWSER_FAIL = 'browserFail',
  WIFI_SUCCESS = 'wifiSuccess',
  WIFI_FAIL = 'wifiFail',
  INTRO_VIDEO_VOLUME_ON = 'introVideoVolumeOn',
  INTRO_VIDEO_VOLUME_OFF = 'introVideoVolumeOff',
  VIRTUAL_BACKGROUND_ON = 'virtualBackgroundOn',
  VIRTUAL_BACKGROUND_OFF = 'virtualBackgroundOff',
  SMILE_EMOJI = 'smileEmoji',
  RAISED_HAND_V2 = 'raisedHandV2',
  SURVEYS = 'surveys',
  CLOSED_CAPTION = 'caption',
  SETTINGS = 'settings',
}

export enum IContentColors {
  NEUTRAL_BASE_1 = 'neutral-base-1',
  NEUTRAL_BASE_2 = 'neutral-base-2',
  NEUTRAL_BASE_3 = 'neutral-base-3',
  NEUTRAL_BASE_3_RGB = 'neutral-base-3-rgb',

  NEUTRAL_MID_1 = 'neutral-mid-1',
  NEUTRAL_MID_2 = 'neutral-mid-2',
  NEUTRAL_MID_3 = 'neutral-mid-3',

  NEUTRAL_CONTRAST_1 = 'neutral-contrast-1',
  NEUTRAL_CONTRAST_2 = 'neutral-contrast-2',
  NEUTRAL_CONTRAST_3 = 'neutral-contrast-3',
  NEUTRAL_CONTRAST_4 = 'neutral-contrast-4',

  CONTRAST = 'contrast-base',
  PRIMARY = 'primary-base',
  SECONDARY = 'secondary-base',

  SUCCESS = 'success-base',
  DANGER = 'danger-base',
  WARNING = 'warning-base',

  MONOCHROME_BASE = 'neutral-monochrome-base',
  MONOCHROME_CONTRAST = 'neutral-monochrome-contrast',

  BLACK = 'base-black-base',
  WHITE = 'base-white-base',

  BLUE = 'colors-navy-blue',
  GRAY_BLUE = 'colors-gray-blue',
  SILVER_CHALICE = 'colors-silver-chalice',
  CETACEAN_BLUE = 'colors-cetacean-blue',
  LIGHT_GRAYISH = 'colors-light-grayish',
  NAVBAR_SECONDARY = 'navbar-secondary-base',
  NAVBAR_PRIMARY = 'navbar-primary-base',

  COLORS_BRIGHT_GREEN = 'success-bright',

  TEXT_WHITE_DISABLED = 'text-white-disabled',
  TEXT_WHITE_MEDIUM = 'text-white-medium',
}

export enum IContentTextColorTypes {
  DEFAULT = 'text-neutral-monochrome-contrast',
  NEUTRAL_LIGHTEST = 'text-neutral-base-1',
  NEUTRAL_LIGHT = 'text-neutral-base-2',
  NEUTRAL_LIGHT_3 = 'text-neutral-base-3',
  NEUTRAL_LIGHT_4 = 'text-neutral-base-4',
  NEUTRAL_MEDIUM = 'text-neutral-mid-3',
  NEUTRAL_MEDIUM_2 = 'text-neutral-mid-2',
  NEUTRAL = 'text-neutral-mid-1',
  NEUTRAL_DARK = 'text-neutral-contrast-1',
  NEUTRAL_DARKEST = 'text-neutral-contrast-2',
  NEUTRAL_DARKEST_3 = 'text-neutral-contrast-3',
  NEUTRAL_DARKEST_4 = 'text-neutral-contrast-4',

  MONOCHROME_CONTRAST = 'text-neutral-monochrome-contrast',
  MONOCHROME_BASE = 'text-neutral-monochrome-base',
  CONTRAST = 'text-contrast',
  PRIMARY = 'text-primary',
  SECONDARY = 'text-secondary',

  SUCCESS = 'text-success',
  WARNING = 'text-warning',
  DANGER = 'text-danger',

  BLACK = 'text-base-black',
  WHITE = 'text-base-white',
  BLUE = 'text-colors-navy-blue',
  GRAY_BLUE = 'text-colors-gray-blue',
  INHERIT = 'inherit',

  TEXT_WHITE_MEDIUM = 'text-white-medium',
}

export enum IGeneralSizeTypes {
  DEFAULT = 'default',
  XXSMALL = 'xxsmall',
  XSMALL = 'xsmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xlarge',
}

// default
export enum IContentTextSizeTypes {
  DEFAULT = 'defaultTextSize',
  XXSMALL = 'xxsmallTextSize',
  XSMALL = 'xsmallTextSize',
  SMALL = 'smallTextSize',
  MEDIUM = 'mediumTextSize',
  MLARGE = 'midLargeTextSize',
  LARGE = 'largeTextSize',
  XLARGE = 'xlargeTextSize',
  XMLARGE = 'xmidLargeTextSize',
  XXLARGE = 'xxlargeTextSize',
}

export enum IContentTextStyleTypes {
  DEFAULT = '',
  BOLDED = 'bolded',
  SEMI_BOLDED = 'semiBolded',
  CAPITALIZED = 'capitalized',
  ITALICIZED = 'italicized',
  UNSET = 'noStyle',
  REGULAR = 'regular',

  SEMI_BOLDED_CAPITALIZED = 'semiBoldedCapitalized',
  SEMI_BOLDED_CAPITALIZED_ITALICIZED = 'semiBoldedCapitalizedItalicized',
  SEMI_BOLDED_ITALICIZED = 'semiBoldedItalicized',

  BOLDED_CAPITALIZED = 'boldedCapitalized',
  BOLDED_CAPITALIZED_ITALICIZED = 'boldedCapitalizedItalicized',
  BOLDED_ITALICIZED = 'boldedItalicized',
  CAPITALIZED_ITALICIZED = 'capitalizedItalicized',
}

export enum IContentTextMarginTypes {
  NO_MARGIN = 'noMargin',
  SMALL_MARGIN_RIGHT = 'smallMarginRight',
  SMALL_MARGIN_LEFT = 'smallMarginLeft',
  SMALL_MARGIN_BOTTOM = 'smallMarginBottom',
  SMALL_MARGIN_TOP = 'smallMarginTop',
  XSMALL_MARGIN_TOP = 'xSmallMarginTop',
  XSMALL_MARGIN_RIGHT = 'xSmallMarginRight',
  XSMALL_MARGIN_BOTTOM = 'xSmallMarginBottom',
  XSMALL_MARGIN_LEFT = 'xSmallMarginLeft',
  MEDIUM_MARGIN_RIGHT = 'mediumMarginRight',
  MEDIUM_MARGIN_LEFT = 'mediumMarginLeft',
  MEDIUM_MARGIN_BOTTOM = 'mediumMarginBottom',
  MEDIUM_MARGIN_TOP = 'mediumMarginTop',
  MEDIUM_MARGIN_BOTTOM_RIGHT = 'mediumMarginBottomRight',
  SMALL_MARGIN_BOTTOM_TOP = 'smallMarginBottomTop',
  MEDIUM_MARGIN_TOP_RIGHT = 'mediumMarginTopRight',
}

export enum IContentBGColorTypes {
  DEFAULT = 'background-unset',
  TRANSPARENT = 'background-transparent',

  NEUTRAL_LIGHTEST = 'background-neutral-base-1',
  NEUTRAL_LIGHT = 'background-neutral-base-2',
  NEUTRAL_LIGHT_3 = 'background-neutral-base-3',
  NEUTRAL_MEDIUM = 'background-neutral-mid-1',
  NEUTRAL = 'background-neutral-mid-2',
  NEUTRAL_DARK = 'background-neutral-contrast-2',
  NEUTRAL_DARKEST = 'background-neutral-contrast-3',

  CONTRAST = 'background-contrast',
  PRIMARY = 'background-primary',

  SUCCESS = 'background-success',
  DANGER = 'background-danger',

  BLACK_TRANSPARENT = 'darkestTransparentBlackBG',

  BLACK = 'background-black',
  WHITE = 'background-white',
  BLUE = 'background-blue',
}

export enum IBadgeTypes {
  PRIMARY = 'primaryBadge',
  DARK = 'darkBadge',
  LIGHT = 'lightBadge',
  SMALL_PRIMARY = 'smallPrimaryBadge',
  SMALL_WHITE = 'smallWhiteBadge',
  RED = 'redBadge',
}

export enum ILiveEventZones {
  BACKSTAGE = 'backstage',
  CUSTOM_LOBBY = 'customLobby',
  DISCUSSIONS = 'table',
  EXPO = 'expo',
  LOBBY = 'lobby',
  SCHEDULE = 'schedule',
  SPONSORS = 'sponsors',
  STAGE = 'stage',
  NETWORKING = 'networking',
}

export enum ILiveEventColorThemes {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
  CUSTOM = 'CUSTOM',
}

export type ObjectValues<T> = T[keyof T];
